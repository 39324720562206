import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import '../styles/footer.scss';

const SECFooter = () => {
    return (
        <footer>
            <Container fluid className="footer">
                <Container fluid="sm">
                    <Row className="d-flex align-items-center text-center">
                        <Col xs={12} md={4}>
                            <a href="https://www.ucalgary.ca" target="_blank" rel="noreferrer">
                                <img src="/img/logos/university_of_calgary.png" width="50%" alt="University of Calgary logo" />
                            </a>
                        </Col>
                        <Col xs={12} md={4}>
                            <a href="https://www.ualberta.ca" target="_blank" rel="noreferrer">
                                <img src="/img/logos/university_of_alberta.png" width="50%" alt="University of Alberta logo" />
                            </a>
                        </Col>
                        <Col xs={12} md={4}>
                            <a href="https://www.athabascau.ca/" target="_blank" rel="noreferrer">
                                <img src="/img/logos/athabasca_university.png" width="50%" alt="Athabasca University logo" />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </footer>
    );
};

export default SECFooter;