import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "../styles/carousel.scss";

const CAROUSEL_SLIDES = [
    <>
        <h4 className="mb-3">
            <a href="https://doi.org/10.1002/2013GL059024" target="_blank" rel="noreferrer">Usanova et al. [2014]</a>
        </h4>
        <p>
            Here we focus on a <a href="https://doi.org/10.1002/2013GL059024" target="_blank" rel="noreferrer">2014 study</a> by UAlberta
            PhD student Maria Usanova. In this paper, which has been cited more than 230 times, Usanova used data from the NASA Radiation Belt Storm Probes (near the
            magnetospheric equator) and magnetically conjugate CARISMA magnetometer observations to demonstrate Electromagnetic Ion Cyclotron Waves cause
            ultra-relativistic electron precipitation, but this does not affect the core radiation belt electron distribution. This is an example of Space Environment
            Canada data being used to advance the science of a major NASA mission.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/usanova-2014.jpg" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/usanova-2014.jpg" alt="Figure from Usanova et al. [2014]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 1 from <a href="https://doi.org/10.1002/2013GL059024" target="_blank" rel="noreferrer">Usanova et al. [2014]</a>.
                Their figure caption is, quoted from the article "EMIC wave observations from the CARISMA (a) PINA and (b) DAWS magnetometers, from (c) GSM Bx component
                from Van Allen Probe B, (d–f) together with loss of energetic protons on the POES 16 satellite on 11 October 2012. (g) On POES 16, &gt;0.7 MeV electron
                loss was not observed at the same time. The central panel shows the schematic locations of the satellites and ground magnetometers."
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3">
            <a href="https://doi.org/10.1029/2001JA009198" target="_blank" rel="noreferrer">Donovan et al. [2003]</a>
        </h4>
        <p>
            In this <a href="https://doi.org/10.1029/2001JA009198" target="_blank" rel="noreferrer">2003 study</a> by University of
            Calgary Professor Eric Donovan, a new method to identify the equatorward boundary of the proton aurora (also known as the b2i boundary) is investigated.
            This boundary, which marks the transition from strong pitch angle scattering to bounce trapped particles is a pivotal parameter to constrain empirical models.
            This article, which has been cited over 80 times, utilizes DMSP satellite observations and Meridian Scanning Photometer (MSP) to show that the location of
            the optical b2i boundary can be used to identify the equatorward boundary of significant proton aurora, a vital metric for identifying the magnetotail index.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/donovan_et_al_2003_fig8.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/donovan_et_al_2003_fig8.png" alt="Figure 8 from Donovan et al. [2003]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 8 from <a href="https://doi.org/10.1029/2001JA009198" target="_blank" rel="noreferrer">Donovan et al. [2003]</a>.
                Their figure caption, quoted from the article, is: “GOES 8 magnetic field inclination (top) and Gillam proton auroral data (bottom) for 0200 – 1100 UT
                on 24 March 1996. Five-minute averages of the locations of the optical b2i, determined with the algorithm discussed in section 2, are indicated with the
                red diamonds on the keogram.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3">
            <a href="https://ics8.ca/proc_files/donovan.pdf" target="_blank" rel="noreferrer">Donovan et al. [2006]</a>
        </h4>
        <p>
            In this <a href="https://ics8.ca/proc_files/donovan.pdf" target="_blank" rel="noreferrer">2006 study</a> by University of Calgary Professor Eric Donovan, a
            new connection to substorm onsets is identified. In this paper, which has been cited over 60 times, Donovan used data from the THEMIS all-sky imagers to study
            the azimuthal progression of a pre-existing auroral arc and the creation of a ‘bead structure’ prior to substorm unset. They connected this to a current
            disruption in the inner magnetosphere. This is an example of Space Environment Canada data being used to advance the theory into substorm onset.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/donovan_et_al_2006_fig5.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/donovan_et_al_2006_fig5.png" alt="Figure 5 from Donovan et al. [2006]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Adaption of Figure 5 from <a href="https://ics8.ca/proc_files/donovan.pdf" target="_blank" rel="noreferrer">Donovan et al. [2006]</a>. Their figure caption,
                quoted from the article, is: “The left column consists of partial Fort Yukon white light ASI images (roughly the bottom quarter of the image in each case)
                separated in time by 6 seconds with time increasing down on the plot. The time corresponding to each image is indicated in hhmmss format. The images go
                from 10:11:12 UT through 10:12:54 UT, and so span 1 minute and 42 seconds (as an aside, we note that the THEMIS ASIs will operate at a cadence of one image
                every three seconds. The right-hand column consists of differences between successive images in false color. We have mapped a range of differences around
                zero to white so as to suppress the effects of CCD readout noise.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3">
            <a href="https://doi.org/10.1126/sciadv.aaq0030" target="_blank" rel="noreferrer">MacDonald et al. [2018]</a>
        </h4>
        <p>
            This study led by Elizabeth MacDonald from NASA (<a href="https://doi.org/10.1126/sciadv.aaq0030" target="_blank" rel="noreferrer">MacDonald et al.
                [2018]</a>) focuses on the ionospheric phenomena known as “STEVE” and the picket fence.  Cited nearly 70 times, this study utilizes Canada’s Redline Geospace
            Observatory (REGO), the Electric Field Instrument (EFI) – the Canadian component on board ESA’s Swarm mission, and observations from amateur astronomers from
            the Alberta Aurora Chaser Community, MacDonald et al.’s study investigated a strange ionospheric signature now associated with subauroral ion drifts – a not
            yet understood form of airglow. The impact on the Space Science Community from this study is two-fold: A new form of airglow has been identified sparking a
            whole new area of research and a new relationship between citizen auroral scientists and the scientific community is evolving as a direct result.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/macdonald_et_al_2018_fig1-3.png" target="_blank" rel="noreferrer">
                <img width="80%" src="/img/discovery/macdonald_et_al_2018_fig1-3.png" alt="Figures 1-3 from MacDonald et al. [2018]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                (Top Left) Figure 1 from <a href="https://doi.org/10.1126/sciadv.aaq0030" target="_blank" rel="noreferrer">MacDonald et al. [2018]</a>.
                Their figure caption, quoted from the article, is: “An observation of the subauroral arc (purple) known as STEVE, recorded on 25 July 2016 (05:51 to
                06:10 UT) from Regina, Saskatchewan. The arc is located ~4° equatorward of the main auroral oval [green glow, bottom right in (A) and (B)] and runs
                across the sky in the east-west direction. Small green auroral features, resembling a picket fence, are also observed in (B) and (C). All images in
                the sequence can be found in the video linked to in the Supplementary Materials.” (Bottom Left) Figure 2
                from <a href="https://doi.org/10.1126/sciadv.aaq0030" target="_blank" rel="noreferrer">MacDonald et al. [2018]</a>. Their figure caption,
                quoted from the article, is: “STEVE event observed on 25 July 2016 by the Lucky Lake REGO ASI at 06:45 UT around 59.5° latitude. As indicated, the STEVE
                arc is the narrow enhancement spanning nearly the whole field of view. The superposed rectangle corresponds to the Athabasca FESO MSP, located at the
                Athabasca University Geophysics Observatory (AUGO), data in which the proton aurora is observed at least 2° poleward of STEVE. The blue labels indicate
                the locations of AUGO, major cities, and the citizen science observations of STEVE. The bright patches in the center of the image are artifacts and not
                genuine sources of light.” (Right) Figure 3 from <a href="https://doi.org/10.1126/sciadv.aaq0030" target="_blank" rel="noreferrer">MacDonald et
                    al. [2018]</a>. The conjunction (highlighted in the pink region) of the STEVE optical emission with a pronounced SAID signature at satellite altitudes.
                A plot of the Lucky Lake REGO ASI intensity along the satellite track mapped at three different altitudes (A), together with Swarm A satellite measurements
                (B to E). Plotted in (B) is the ion velocity (positive is eastward flow), in (C) is the eastward magnetic field deviation relative to the undisturbed
                geomagnetic field, in (D) is the ambient electron temperature, and in (E) is the electron number density.
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3">
            <a href="https://doi.org/10.1029/2008GL033794" target="_blank" rel="noreferrer">Donovan et al. [2008]</a>
        </h4>
        <p>
            This <a href="https://doi.org/10.1029/2008GL033794" target="_blank" rel="noreferrer">2008 study</a> is led by University
            of Calgary Professor Eric Donovan. In this study, Donovan et al. Use the THEMIS ground-based imagers in conjunction with THEMIS satellites to explore the
            connection between optical substorm onset and activity along field lines connected to the inner central plasma sheet.  This study was instrumental in
            identifying that the expansion phase of a substorm begins earthward of the satellite location and evolved radially outward in azimuthally localized regions
            of highly stretched field lines.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/donovan_et_al_2008_fig3.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/donovan_et_al_2008_fig3.png" alt="Figure 3 from Donovan et al. [2008]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 3 from <a href="https://doi.org/10.1029/2008GL033794" target="_blank" rel="noreferrer">Donovan et al., [2008]</a>. Their figure caption, quoted
                from the article, is: “<a href="https://doi.org/10.1029/2008GL033794#grl24555-fig-0003" target="_blank" rel="noreferrer">Figure 3</a> is a stack plot of
                data from selected satellite and ground-based instruments. The two keograms show ASI data on the Pinawa and La Ronge meridians. The 0507 onset is indicated
                by the two red circles. THEMIS FGM Bx and the magnetic inclination at GOES 11 show dipolarization and TH-A ElectroStatic Analyzer (plasma distribution
                for &lt;30 keV ions and electrons) (J. P. McFadden et al., THEMIS ESA first science results and performance issues, submitted to Space Science Review, 2008)
                data (not shown) shows CPS thickening associated with the onset (the ESA data shows TH-A to be in the CPS or PSBL at the time of onset). The La Ronge
                magnetometer shows a clear Pi2 burst and negative H-bay with the onset. The Pinawa H-beta keogram shows fading as
                discussed by <a href="https://doi.org/10.1029/2008GL033794#grl24555-bib-0011" target="_blank" rel="noreferrer">Liu et al. [2007]</a> in
                the 15 minutes prior to the first event. The red curve superposed on the bottom two
                plots of <a href="https://doi.org/10.1029/2008GL033794#grl24555-fig-0003" target="_blank" rel="noreferrer">Figure 3</a> shows the “optical b2I”, a proxy
                for the equatorward boundary of the proton aurora (see Donovan et al. [2003]). As well, in the minutes after the onset there is intrusion of electron
                aurora equatorward of the proton aurora indicating electron injection (indicated by the green circle).”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/92GL00116" target="_blank" rel="noreferrer">Samson et al. [1992]</a></h4>
        <p>
            John Samson, a former University of Alberta Professor, was a pioneer in field-line resonance theory.  This study,
            from <a href="https://doi.org/10.1029/92GL00116" target="_blank" rel="noreferrer">1992</a>, has been cited over 238 times. Samson et al.’s discovery of
            ‘magic frequencies’ parameterizing field-line resonances and linking them to a magnetospheric waveguide model would become one of the prevailing theories
            for field-line resonances for many years.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/samson_et_al_1992_fig1-2.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/samson_et_al_1992_fig1-2.png" alt="Figures 1 and 2 from Samson et al. [1992]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                (Left) Figure 1 from <a href="https://doi.org/10.1029/92GL00116" target="_blank" rel="noreferrer">Samson et al. [1992]</a>. Their figure caption,
                quoted from the article, is:  “Power spectra as a function of PACE latitude [Baker and Wing, 1989] for the line-of-sight Doppler velocities measured
                on beam 9 of the JHU/APL HF radar in the interval 0400 to 0600 UT, 11 January 1989. The power spectra are normalized to give a maximum of 1.0 at each
                latitude”. (Right) Figure 2 from <a href="https://doi.org/10.1029/92GL00116" target="_blank" rel="noreferrer">Samson et al. [1992]</a>. Their figure
                caption, quoted from the article, is: “The waveguide model we have used for our calculations. Top: The turning points in the equatorial plane. The
                first three eigenmodes are shown. Bottom: A cut in the local dawn meridian. A schematic of the radial displacement for n=2 is given in the insert at
                the bottom”.
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/91JA03156" target="_blank" rel="noreferrer">Samson et al. [1992]</a></h4>
        <p>
            In this <a href="https://doi.org/10.1029/91JA03156" target="_blank" rel="noreferrer">1992 study</a> by John Samson at the University of Alberta, CANOPUS
            magnetometer data is used to study the presence of magnetohydrodynamic, field line resonances in the nightside magnetosphere.  Cited almost 200 times,
            this paper was the first to suggest that this type of geometric resonance is due to cavity modes or waveguide mode between the magnetopause and turning
            points on dipole like magnetic shells.  It was also one of the first studies to suggest that field line resonances may play an important role in triggering
            or producing substorm intensification. This is an example of Space Environment Canada advancing the science needed for substorm modelling.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/samson_et_al_1992_fig10-14a.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/samson_et_al_1992_fig10-14a.png" alt="Figures 10 and 14a from Samson et al. [1992]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                (Left) Figure 10 from <a href="https://doi.org/10.1029/91JA03156" target="_blank" rel="noreferrer">Samson et al. [1992]</a>.  Their figure caption,
                quoted from the article, is: “X component magnetometer data from the CANOPUS magnetometer array on December 7, 1989”. (Right) Figure 14a
                from <a href="https://doi.org/10.1029/91JA03156" target="_blank" rel="noreferrer">Samson et al. [1992]</a>.  Their figure caption, quoted from the
                article, is: “MPA data from GILL for December 8, 1989. The scale for the shading (right) is logarithmic.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2002JA009801" target="_blank" rel="noreferrer">Baker et al. [2003]</a></h4>
        <p>
            In this <a href="https://doi.org/10.1029/2002JA009801" target="_blank" rel="noreferrer">2003 study</a> by Greg Baker (then a masters student at the
            University of Calgary) a particular type of wave pulsation, known as pc5 pulsations, surveyed and analysed.  This paper, currently cited 75 times, utilizes
            over 10 years of CANOPUS magnetometer data to correlate pc5 pulsation behavior to solar wind and IMF characteristics.  An important impact of this study for
            the Space Science Community was the fact field-line resonances did not occur at set frequencies as previously thought allowing for a shift in constraints for
            future models.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/baker_et_al_2003_fig5-7.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/baker_et_al_2003_fig5-7.png" alt="Figures 5 and 7 from Baker et al. [2003]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                (Left) Figure 5 from <a href="https://doi.org/10.1029/2002JA009801" target="_blank" rel="noreferrer">Baker et al. [2003]</a>. Their figure caption, quoted
                from the article, is: “Kp sorted PSD and occurrence statistics mapped into the equatorial plane using the T87 magnetospheric model. The dashed lines
                indicate the mappings for (with increasing distance from the Earth) 61°, 67°, 69°, and 73° magnetic latitude. The occurrence statistics scale (in all
                three panels) indicates the probability of seeing a Pc5 pulsation during a given hour, at a single station each day. The PSD scales are given in 103
                nT2/Hz.” (Right) Figure 7 from <a href="https://doi.org/10.1029/2002JA009801" target="_blank" rel="noreferrer">Baker et al. [2003]</a>. Their figure
                caption, quoted from the article, is: “Correlations between Pc5 band PSD and solar wind flow speed at Fort Churchill for 0600–1200 MLT (top), 1200–1800
                MLT (middle), and 1800–0600 MLT (bottom).”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.3389/fphy.2021.744298" target="_blank" rel="noreferrer">Gabrielse et al. [2021]</a></h4>
        <p>
            This recent study by Christine Gabrielse from the Aerospace Corporation
            (<a href="https://doi.org/10.3389/fphy.2021.744298" target="_blank" rel="noreferrer">Gabrielse et al. [2021]</a>) takes a new look at utilizing the THEMIS
            all-sky imaging array in a new light. Combining THEMIS white-light all-sky imagers and the CANOPUS Meridian-Scanning Photometers arrays, Gabrielse et
            al. estimate the scales sizes of precipitating energy fluxes and associated Hall conductance. This innovative project allows the THEMIS ground-based array
            to be used as a tool to study auroral precipitation in 2D over time, an incredible adaptation of the ground-based array network.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/gabrielse_et_al_2021_fig8.jpg" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/gabrielse_et_al_2021_fig8.jpg" alt="Figure 8 from Gabrielse et al. [2021]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 8 from <a href="https://doi.org/10.3389/fphy.2021.744298" target="_blank" rel="noreferrer">Gabrielse et al. [2021]</a>. Their figure caption, quoted
                from the article, is: “Comparing the ASI-determined Hall conductance to PFISR-determined Hall conductance. The discrete and diffuse aurora are marked
                in panels a–c. The Gaussian distribution was used to determine the conductance related to discrete aurora, and the Maxwellian distribution was used
                for the diffuse aurora. (A) The FYKN white light intensity keogram at the PFISR longitude. The PFISR latitude line is drawn horizontally. (B) Hall
                conductance using the Gaussian energy fluxes and average energies from the ASI as input to B3C (red) and Robinson formula (blue) alongside the
                PFISR-determined result (black). (C) Hall conductance using the Maxwellian energy fluxes and average energies from the ASI as input to B3C (red) and
                Robinson formula (blue) alongside the PFISR-determined result (black). (D) 2D snapshot of Hall conductance assuming a Maxwellian distribution at
                09:36:30 UT. (E) 2D snapshot of Hall conductance assuming a Gaussian distribution at 09:40:00. (F) 2D snapshot of Hall conductance assuming a Gaussian
                distribution at 09:41:30. (G) 2D snapshot of Hall conductance assuming a Maxwellian distribution at 10:30:30 UT.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2019GL083272" target="_blank" rel="noreferrer">Gillies et al. [2019]</a></h4>
        <p>
            A <a href="https://doi.org/10.1029/2019GL083272" target="_blank" rel="noreferrer">2019 study</a> by Megan Gillies, a researcher at the University of Calgary,
            looked the spectra associated with STEVE and the Picket Fence.  Cited approximately 25 times, this study uses the Transition Region Explorer (TREx)
            Spectrograph to analyse the spectrum of STEVE and compares it to traditional auroral features.  This study separates STEVE from traditional aurora by way of
            its unique spectral signature solidifying the notion that this type of luminous air glow is not formed by electron precipitation – a useful component to
            constrain generation models and theories of this unique atmospheric feature.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/gillies_et_al_2019_fig1.jpg" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/gillies_et_al_2019_fig1.jpg" alt="Figure 1 from Gillies et al. [2019]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 1 from  <a href="https://doi.org/10.1029/2019GL083272" target="_blank" rel="noreferrer">Gillies et al. [2019]</a>. Their figure caption, quoted
                from the article, is: “Full spectrum of the STEVE emission obtained at 06:40:15 UT on 10 April 2018 from Lucky Lake, SK. Typical auroral emission lines
                (blue—427.8 nm, green—557.7 nm, and red—630.0 nm) are marked with arrows. STEVE is depicted by the blue line, and the apparent background is marked by
                the green and red lines. Background estimates have been taken from approximately 3σ equatorward and poleward from the position of STEVE. Inset: Keogram
                of STEVE at all wavelengths as a function of time (x axis) in UT and space (elevation bin). North is at high elevation bin and south is at low elevation
                bin. Position of spectrum utilized for STEVE is depicted by the blue square, background spectra by the green and red squares. Data have been smoothed to
                reduce noise effects. STEVE = Strong Thermal Emission Velocity Enhancement.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.5194/angeo-23-1533-2005" target="_blank" rel="noreferrer">Spanswick et al. [2005]</a></h4>
        <p>
            This <a href="https://doi.org/10.5194/angeo-23-1533-2005" target="_blank" rel="noreferrer">2005 study</a> by University of Calgary Professor Emma Spanswick
            highlights the importance of high energy electron precipitation to magnetospheric-Ionospheric coupling.  Using Canadian NORSTAR riometers and CANOPUS
            magnetometers, Spanswick et al. studied ultra-low frequency (ULF) waves and their connection to geospace to show that waves in the pc5 frequency could drive
            the precipitation of high energy electrons into the ionosphere.  The realization that field-line resonances were more efficient at driving the precipitation
            than non-field line resonances is an important result in the Space Science Community, helping to shape future modelling.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/spanswick_et_al_2005_fig9.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/spanswick_et_al_2005_fig9.png" alt="Figure 9 from Spanswick et al. [2005]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 9 from <a href="https://angeo.copernicus.org/articles/23/1533/2005/angeo-23-1533-2005.pdf" target="_blank" rel="noreferrer">Spanswick et al. [2005]</a>. Their figure caption,
                quoted from the article, is: “MLT Occurrence of riometer pulsations corresponding to field line resonances from Baker et al. (2003) and non-field line
                resonances.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1002/2017JA024439" target="_blank" rel="noreferrer">Wu et al. [2017]</a></h4>
        <p>
            This <a href="https://doi.org/10.1002/2017JA024439" target="_blank" rel="noreferrer">2017 study</a>, by former University of Calgary Ph.D. candidate Jiashu
            Wu, highlights current sheet formation associated with multiple arc systems. Cited approximately 20 times, it Utilizes THEMIS ground based white light
            imagers, Wu et al. attributes multiple auroral arc systems appearing with highly structured unipolar field-aligned currents to a generation mechanism tied
            to a magnetospheric-ionospheric coupling process rather than a structured generator deep in the magnetosphere. This is an important contribution for
            constraining auroral arc generation theories and is a vital addition to the literature.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/wu_et_al_2017_fig1.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/wu_et_al_2017_fig1.png" alt="Figure 1 from Wu et al. [2017]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 1 from <a href="https://doi.org/10.1002/2017JA024439" target="_blank" rel="noreferrer">Wu et al. [2017]</a>. Their figure caption, quoted from
                the article, is: “Multiple arcs observed by the Fort Smith ASI and Swarm A with a unipolar FAC system on 27 September 2014. (a) The Swarm A trajectory
                and multiple arcs in the ASI images at 1 minute time intervals. The satellite footprint is traced along the magnetic field line to an altitude of 110
                km. red and blue colors indicate upward and downward FACs. (b) Satellite-aligned keogram of Swarm A as a function of geomagnetic latitude and time.
                Mapping to AACGM coordinates is done using an assumed emission height of 110 km. The values of upward (red) and downward (blue) FACs are displayed
                together with the flight path. (c) Horizontal cross-track (approximately eastward) ion velocity in meters per second. (d) Eastward component of residual
                magnetic field. (e) Field-aligned current density j∥ (μA/m2) derived from the single satellite method. The red and blue areas in Figures 1c–1e are
                large-scale upward and downward FAC regions identified interactively by neglecting small-scale fluctuations. The positions of peak brightness of arcs
                are indicated by green lines.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2008GL033666" target="_blank" rel="noreferrer">Liang et al. [2008]</a></h4>
        <p>
            This <a href="https://doi.org/10.1029/2008GL033666" target="_blank" rel="noreferrer">2008 study</a>, led by University of Calgary Researcher Jun Liang,
            focuses on substorm onset. Cited over 120 times, it uses the THEMIS (Time History of Events and Macroscale Interactions During Substorms) imaging network
            to take a close look at the time evolution of the optical substorm. Identifying the timescale for the optical substorm onset to be 10-30 seconds allows
            for important observational constraints on substorm onset theories which can then be used by the Scientific Modelling Community.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/liang_et_al_2008_fig1-3.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/liang_et_al_2008_fig1-3.png" alt="Figures 1 and 3 from Liang et al. [2008]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                (Left) Figure 1 from <a href="https://doi.org/10.1029/2008GL033666" target="_blank" rel="noreferrer">Liang et al. [2008]</a>. Their figure caption,
                quoted from the article, is: “A sequence of THEMIS ASI images showing a substorm auroral breakup on February 22, 2006”. (Right) Figure 3
                from <a href="https://doi.org/10.1029/2008GL033666" target="_blank" rel="noreferrer">Liang et al. [2008]</a>. Their figure caption, quoted from the
                article, is:” Stack plot of the longitudinal profile of the latitudinally-integrated auroral brightness around the EP onsets fortwo events. Green
                curve denotes the temporal evolution of total auroral brightness (ticks on upper axis) with time.
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2008GL035727" target="_blank" rel="noreferrer">Miyoshi et al. [2008]</a></h4>
        <p>
            This <a href="https://doi.org/10.1029/2008GL035727" target="_blank" rel="noreferrer">2008 study</a> was led by Y. Miyoshi from the University of Nagoya
            University. The article uses data from an H-beta emission all-sky imager and a magnetometer located in Athabasca, AB. This study shows the importance
            of EMIC plasma waves role in the production of proton arcs.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/miyoshi_et_al_2008_fig1.png" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/miyoshi_et_al_2008_fig1.png" alt="Figure 1 from Miyoshi et al. [2008]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 1 from <a href="https://doi.org/10.1029/2008GL035727" target="_blank" rel="noreferrer">Miyoshi et al. [2008]</a>. Their figure caption, quoted from
                the article, is: “(a) Photograph of the isolated proton auroral arc in the southern sky of Athabasca, Canada, taken on September 5, 2005, and the
                Athabasca observatory building with two all-sky camera domes on the roof. (b) An auroral image at a wavelength of Hβ line obtained by averaging images
                captured from 0430 to 0500 UT. The arc-like structure perpendicular to that arc is the Milky Way, as can be seen in Figure 1a. The dashed line shows
                the trajectory of the POES-17 footprints that are mapped onto the ionospheric altitude of 120 km. The rectangle highlights the location of the isolated
                proton aurora.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2018JA025621" target="_blank" rel="noreferrer">Ren et al. [2018]</a></h4>
        <p>
            This <a href="https://doi.org/10.1029/2018JA025621" target="_blank" rel="noreferrer">2018 study</a> was led by Jiaen Ren, then a Ph.D. student, from the
            University of Michigan. It utilizes the Canadian Incoherent Scatter Radar (RISR-C) to study polar cap patches. The findings indicated that polar cap
            patches exhibit a lower electron temperature than the surrounding background in the noon sector, helping shed light on polar cap patch origin theories.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/ren_et_al_2018_fig1.jpg" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/ren_et_al_2018_fig1.jpg" alt="Figure 1 from Ren et al. [2018]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 1 from <a href="https://doi.org/10.1029/2018JA025621" target="_blank" rel="noreferrer">Ren et al. [2018]</a>. Their figure caption, quoted from
                the article, is: “An example of patch identification procedure for Resolute Bay Incoherent Scatter Radar-Canada measurement on 19 January 2016. The
                first panel shows the electron density obtained directly from power with no range integration and no correction for measured temperature. In the second
                panel, black curve shows average electron density from 250 to 400 km with a 3-point median filter applied. The density peaks that fulfill the criteria
                mentioned in the text are marked by red triangles. Within each peak, the vertical red line and horizontal yellow line indicate its prominence and
                half-prominence width, respectively.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2020JA029090" target="_blank" rel="noreferrer">Nishimura et al. [2021]</a></h4>
        <p>
            This is a recent study by Toshi Nishimura, a professor from the University of Boston. Nishimura et al. utilized both the Canadian RISR-C and National
            Science Foundation RISR-N incoherent scatter radars to observe ionospheric cusp dynamics during an IMF southward turning. Simulations of the resulting
            observations led to the importance of acknowledging soft electron precipitation for the cusp dynamics and initial formation of polar cap patches.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/nishimura_et_al_2021_fig10.jpg" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/nishimura_et_al_2021_fig10.jpg" alt="Figure 10 from Nishimura et al. [2021]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 10 from <a href="https://doi.org/10.1029/2020JA029090" target="_blank" rel="noreferrer">Nishimura et al. [2021]</a>. Their figure caption, quoted
                from the article, is: “Altitude profiles of the Resolute Bay Incoherent Scatter Radar observations at three selected time intervals (black: 18:00-18:20,
                green: 18:20-18:30, and red: 18:30-18:40 UT), for the parameters in Figure 3. The field-aligned velocity in Panel c is from beam 50. For the other
                panels, we selected &lt;79° (black), 79°–82° (green) and 82°–83° (red) magnetic latitude.”
            </i></p>
        </div>
    </>,
    <>
        <h4 className="mb-3"><a href="https://doi.org/10.1029/2019JA026797" target="_blank" rel="noreferrer">Engebretson et al. [2019]</a></h4>
        <p>
            This article focuses on a <a href="https://doi.org/10.1029/2019JA026797" target="_blank" rel="noreferrer">2019 study</a> by M. Engebretson from Augsburg
            University.  Using magnetometers from the CARISMA, MACCS, CANMOS, and AUTUMNX arrays spread across the Canadian Artic, this study shows how rapid changes
            in magnetic fields associated with nighttime perturbations fo the geomagnetic field can induce currents with magnitudes capable of harming technological s
            ystems. This study is an example of how the Space Environment Canada data is important for monitoring space weather implication for Canada.
        </p>
        <div className="mt-4 text-center">
            <a href="/img/discovery/engebretson_et_al_2019_fig4.jpg" target="_blank" rel="noreferrer">
                <img className="carousel-img" src="/img/discovery/engebretson_et_al_2019_fig4.jpg" alt="Figure 4 from Engebretson et al. [2019]" />
            </a>
        </div>
        <div className="m-5">
            <p className="col-md-10 offset-md-1 mt-5 text-center" style={{ fontSize: "12px" }}><i>
                Figure 4 from <a href="https://doi.org/10.1029/2019JA026797" target="_blank" rel="noreferrer">Engebretson et al. [2019]</a>. Their figure caption, quoted
                from the article, is: “(a–d) Equivalent ionospheric current vectors (panels a and c) and contour maps of the horizontal derivative of the magnetic
                field above Arctic Canada and western Greenland (panels b and d), calculated by applying the Spherical Elementary Current Systems technique to 10-s
                cadence vector magnetometer data. The value of the derivative is coded according to the color bar below panel (d). Panels (a) and (b) show values at
                0100 UT, and panels (c) and (d) show values at 0119 UT, during the 11 November 2015 magnetic perturbation event shown in Figure 3. (e and f) Auroral
                images obtained by the all-sky imager at Rankin Inlet at 0113 and 0116:45 UT, respectively. The locations of Rankin Inlet, Repulse Bay, and Igloolik
                are denoted by red crosses.”
            </i></p>
        </div>
    </>
];

const DiscoveryCarousel = () => {
    const [carouselIndex, setCarouselIndex] = useState(0);
    const handleCarouselClick = (index) => {
        setCarouselIndex(index > CAROUSEL_SLIDES.length - 1 ? 0 : index < 0 ? CAROUSEL_SLIDES.length - 1 : index);
    };

    // The following is an attempt at setting an interval to cycle through the slides... it needs some work.
    // The idea is that the interval will clear itself when the user mouses over the slide, and then restart
    // when the user's mouse leaves the slide.

    // const goToNextSlide = () => {
    //     handleCarouselClick(carouselIndex => carouselIndex + 1 > CAROUSEL_SLIDES.length - 1 ? 0 : carouselIndex < 0 ? CAROUSEL_SLIDES.length - 1 : carouselIndex + 1);
    // };

    // useEffect(() => {
    //     let slideTimer = setInterval(goToNextSlide, 3000);
    //     document.getElementById("discovery-slides").addEventListener("mouseenter", () => {
    //         clearInterval(slideTimer);
    //     });

    //     document.getElementById("discovery-slides").addEventListener("mouseleave", () => {
    //         slideTimer = setInterval(goToNextSlide, 3000);
    //     });

    //     return () => clearInterval(slideTimer);
    // }, []);

    return (
        <Container id="discovery-carousel" fluid style={{ minHeight: 800 }}>
            <Row className="mt-4">
                <Col xs={1}
                    title="Previous"
                    onClick={() => handleCarouselClick(carouselIndex - 1)}
                    style={{ cursor: "pointer", marginTop: 38 }}
                    className="discovery-carousel-prev" >
                    <BiChevronLeft fill="#6d56a0" size={36} />
                </Col>
                <Col xs={10}>
                    <Row className="mt-5 mb-5">
                        {CAROUSEL_SLIDES.map((s, index) => <div
                            key={`discovery-slide-${index}`}
                            style={{
                                height: 10,
                                backgroundColor: index === carouselIndex ? "#6d56a0" : "rgb(202 194 221)",
                                cursor: "pointer", border: "1px white solid",
                                width: `${100 / CAROUSEL_SLIDES.length}%`,
                                display: "inline-block"
                            }}
                            onClick={() => handleCarouselClick(index)}
                        />)}
                    </Row>
                    <div id="discovery-slides">
                        {CAROUSEL_SLIDES[carouselIndex]}
                    </div>
                </Col>
                <Col xs={1}
                    title="Next"
                    onClick={() => handleCarouselClick(carouselIndex + 1)}
                    style={{ cursor: "pointer", marginTop: 38 }}
                    className="d-flex flex-row justify-content-end discovery-carousel-next">
                    <BiChevronRight style={{ cursor: "pointer" }} fill="#6d56a0" size={36} />
                </Col>
            </Row>
            <Row>

            </Row>
        </Container>
    );
};

const DiscoverySection = () => {
    return (
        <Container id="discovery" fluid className="pt-4 pb-3">
            <div id="discovery-scroll" style={{ position: "relative", top: -70 }} />
            <h3>Discovery</h3>
            <p>
                Space Environment Canada produces data that enables remarkably diverse and impactful
                science. Researchers across Canada and around the world use our data to carry out
                cutting-edge fundamental science, leading to closure of long-standing questions and
                discoveries of new processes. This has resulted in hundreds of refereed publications,
                dozens of graduate theses, been the justification for new faculty hires including at
                least five CRCs (Connors, Mann, Donovan, Cully, Spanswick) in Alberta, and has motivated
                major new research initiatives including satellite missions. In many cases the science
                unfolds over decades. Consider the early 1970s characterization of the polarization
                of Ultra Low Frequency (ULF) waves at high geomagnetic latitudes. This result motivated
                former ESA Earth Observing and Science and Robotics Director David Southwood to propose
                a theory of Alfvenic Field Line Resonances (FLRs). In the 1990s and 2000s, ground-breaking
                theoretical and simulation work by UAlberta's John Samson and Robert Rankin linked CANOPUS
                magnetic observations of FLRs to CANOPUS optical observations of auroral arcs, leading to
                the first physically self-consistent model of auroral arcs. In 2017, UCalgary's Megan
                Gillies worked with REGO auroral images to demonstrate that while FLRs produce some
                auroral arcs, the mechanism responsible for most arcs is as yet unknown. Currently,
                researchers including UAlberta's Robert Rankin and UCalgary's David Knudsen are using Space
                Environment Canada data to better constrain the requirements of a successful theory of arc
                generation.
            </p>
            <p>
                These decades-long science threads exist almost as intellectual memes. There are many,
                following the important foci of geospace research, including the mechanism of substorm
                onset, the physical processes in the nightside transition region by which plasma sheet
                particles are injected into the inner magnetosphere, determining the magnetospheric and
                ionospheric origin of STEVE, and the role of ULF waves and wave-particle interactions in
                the acceleration, transport, and loss of radiation belt particles. On this page we will
                present a series of 'science nuggets', highlighting high impact studies in which Space
                Environment Canada data played an important role.
            </p>
            <DiscoveryCarousel />

        </Container>
    );
};

export default DiscoverySection;