import React from "react";
import { Container } from "react-bootstrap";

const AboutPage = () => {
    return (
        <div>
            <Container fluid="sm" className="header text-center" style={{paddingTop: 100}}>
                <h1>About the Facility</h1>
            </Container>
            <Container fluid="sm" className="pt-3 pb-5">
                <p style={{ textAlign: "justify" }}>
                    Exploiting Canada’s geographic advantage as the most accessible landmass under
                    the aurora anywhere in the world, the Geospace Environment Canada facility has
                    the mission to facilitate the unique multi-instrument and multi-scale ground-based
                    scientific exploration of the highly coupled and dynamic near-Earth space plasma
                    environment. Our inclusion in the 1990s as Canada's contribution to the International
                    Solar Terrestrial Physics Program paved the way for an ongoing series of partnerships
                    with international space missions where we have and continue to enable science
                    closure. We partner with NASA and European Space Agency scientific satellite
                    missions, and with researchers from across Canada and around the world who use our
                    data to enable cutting edge science and stimulate innovation.
                </p>
                <p style={{ textAlign: "justify" }}>
                    With a forty-year plus history dating back to an early chain of magnetometers operated
                    by UAlberta, then advanced by the Alberta-led CANOPUS and CGSM programs, and bolstered
                    by 9 CFI IF, Cyber-Infrastructure, and JELF grants, the three proposing Alberta
                    universities have built an internationally unique network of networks spanning Canada
                    from the East coast to Alaska, and from sub-auroral latitudes to deep in the polar
                    cap.
                </p>
                <p style={{ textAlign: "justify" }}>
                    Our ~100 instruments comprise magnetometers, riometers, GPS receivers, spectrographs,
                    all-sky imagers, an observatory (AUGO), and an Incoherent Scatter Radar (ISR) operating
                    across ~45 sites. Our facility constantly evolves, with better and more numerous sensors
                    operating, and ever increasingly sophisticated data access and discovery. We operate
                    continuously, and data dating back to the late 80s is freely and openly available
                    online.
                </p>
                <div className="text-center pt-2">
                    <a href="/img/msi_front_map.png" target="_blank">
                        <img src="/img/msi_front_map.png" alt="Map of SEC instrumentation" className="img-fluid" width="80%" />
                    </a>
                </div>
            </Container>
        </div>
    );
};

export default AboutPage;