import React from "react";
import { Container } from "react-bootstrap";

const DataPolicyPage = () => {
    return (
        <div>
            <Container fluid="sm" className="header text-center" style={{paddingTop: 100}}>
                <h1>Data Policy</h1>
            </Container>
            <Container fluid="sm" className="pt-5 pb-5">
                <p>SEC data is open and freely available online with no requirement for registration
                    or need for a user account.</p>
                <p>We strive to ensure our data is downloadable via easily understood intuitive interfaces
                    and is provided in formats consistent with the standards in our discipline. Data is
                    freely accessible, highly discoverable, and can be read by standard tools.</p>
            </Container>
        </div>
    );
};

export default DataPolicyPage;