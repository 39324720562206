import React from "react";
import { Container } from "react-bootstrap";

const HistoryPage = () => {
    return (
        <div>
            <Container fluid="sm" className="header text-center" style={{paddingTop: 100}}>
                <h1>History</h1>
            </Container>
            <Container fluid="sm" className="pt-5">
                <p style={{ textAlign: "justify" }}>
                    Depending on the context, the study of the physical processes at work in the near-Earth
                    environment is referred to as Solar Terrestrial Physics, Geopsace Research, Space Plasma
                    Physics, and Space Weather Research. As a quantitative science, the modern field emerged
                    with Carl Størmer's and Christian Birkeland's field and laboratory observations in the
                    early 20th century. In 1931, Chapman and Ferraro put forward a new theory of geomagnetic
                    storms which was based on then-unknown giant bursts of charged particles from the sun,
                    things we now call coronal mass ejections, interacting with Earth's magnetic field.
                </p>
                <p style={{ textAlign: "justify" }}>
                    Although the concept of the aurora zone, an oval of aurora roughly centered on the geomagnetic
                    pole, dates back to 19th century explorers including John Franklin (see Loomis, 1860), it
                    took a global effort of optical and magnetic observations during the International Geophysical
                    Year (1959) solidify the concept (see Feldstein, 1963). With the advent of the space age, and
                    especially the race to put a person on the moon, emerging national space programs flew satellites
                    and sub-orbital rockets to explore geospace. The concepts of the solar wind, bow shock,
                    magnetosheath, magnetosphere, ionosphere, thermosphere, and the role of field-aligned currents
                    in coupling the magnetosphere and ionosphere were quickly verified.
                </p>
                <p style={{ textAlign: "justify" }}>
                    In the early 1960s, Gordon Rostoker, then a University of Toronto MSc student was using data
                    from a small network of magnetometers deployed around Toronto to study the Earth's intrinsic
                    magnetic field. He became deeply fascinated with sudden, transient signals in his data, the
                    ground-magnetic signature of a geospace process we now call substorms. This 'noise' quickly
                    became Rostoker's signal, motivating him to PhD and postdoctoral studies where he
                    characterized the phenomena in a series of landmark studies (see, e.g., Rostoker, 1968).
                </p>
                <p style={{ textAlign: "justify" }}>
                    After becoming a faculty member at the University of Alberta in 1968, Rostoker worked with
                    the 'Alberta Chain,' a new network of magnetometers spanning latitudes from below, to
                    poleward of, the auroral oval. In the 70s, he and his grad students did foundational
                    research, paving the way, for example, for development of the Field Line Resonance theory,
                    one of the cornerstones of understanding how geospace works at the system level (see e.g.,
                    Samson et al., 1971, and Samson et al., 1996). At the same time, University of Calgary's
                    Professor Leroy Cogger was developing some of the world's first digital cameras (of any
                    type) with the objective of transforming how optical observations of the aurora are used
                    in geospace research.
                </p>
                <p style={{ textAlign: "justify" }}>
                    This early Alberta research demonstrated the importance of ground-based observations of
                    ionospheric phenomena at high geomagnetic latitudes provide a fundamentally important
                    compliment to space-based observations of the of the same processes and of their
                    magnetospheric drivers. This was a significant motivator for
                    the <a href="https://cgsm.ca/doc/CANOPUS_yellow_book.pdf" rel="noreferrer">1986 NRC
                        proposal</a> for an integrated network of magnetometers, riometers, auroral
                    photometers, a bi-static ionospheric radar, and a digital auroral imager as Canada's
                    contribution to the upcoming International Solar Terrestrial Physics (ISTP) program.
                    The resulting network, called CANOPUS, came online in 1989.
                </p>
                <p style={{ textAlign: "justify" }}>
                    CANOPUS broke new ground on multiple fronts. It firmly established how simultaneous
                    ground-based ionospheric remote sensing together with satellite-borne in situ observations
                    in the ionosphere and magnetosphere can enable one to answer questions that cannot be
                    closed by one, or the other. It demonstrated the value of real-time data in accelerating
                    the science process, on one hand, and its potential for important Space Weather operations,
                    on the other. CANOPUS also demonstrated what we call the 'Canadian advantage,' the unique
                    geographic position of Canada as the country with far and above the largest readily
                    accessible landmass under the auroral ionosphere.
                </p>
                <p style={{ textAlign: "justify" }}>
                    By 2002, CANOPUS had enabled hundreds of refereed papers and dozens of scientific discoveries,
                    motivated the development of world-leading computater simulations of geospace processes, been
                    a significant resource for dozens (if not hundreds) of graduate students and postdoctoral
                    fellows across Canada and around the world, and provided important observational constraints
                    for proposals for major international space missions including NASA's THEMIS (see below). At
                    the same time, the facility was becoming somewhat dated. The bi-static radar never delivered
                    on its requirements (its role was filled by SuperDARN-Canada), the optical instruments were
                    obsolete, and based largely upon what was learned from CANOPUS observations, it was clear that
                    more and in some cases different instruments, and more extensive coverage, were sorely needed
                    to maintain our relevance on the world stage.
                </p>
                <p style={{ textAlign: "justify" }}>
                    A significant development came in 2003 with the approval of the five satellite NASA THEMIS
                    mission, with its scientific objective of determining the magnetospheric instability responsible
                    for substorm onset, and its scientific requirement of auroral (optical and magnetic field)
                    measurements spanning Canada and Alaska (see Angelopoulos, 2008). CANOPUS observations, such
                    as those reported in Voronkov et al. (2003), provided constraints for what THEMIS needed from
                    ground-based observations, and the CANOPUS team at Athabasca University, the University of
                    Alberta, and the University of Calgary partnered with colleagues at University of California,
                    Berkeley, to develop what has become known as the THEMIS Ground-based Observatory network (see
                    Frey et al., 2004, Donovan et al., 2006, Mende et al., 2008, Mann et al., 2008, and Harris et
                    al., 2008). THEMIS-GBO became fully operational in 2007.
                </p>
                <p style={{ textAlign: "justify" }}>
                    In parallel with THEMIS-GBO, from 2002 through 2018, researchers at the Athabasca University,
                    the University of Calgary, and the University of Alberta were successful with nine separate
                    Canada Foundation for Innovation grants that led to an extensive revitalization of what had
                    been CANOPUS. The network now, described on our <a href="/about">About</a> description
                    page, incorporates roughly 100 instruments (two types of magnetometers, six types of auroral
                    cameras, two types of riometers, meridian imaging spectrographs, GPS systems, an auroral
                    observatory, and an incoherent scatter radar. These, together with THEMIS-GBO, an auroral
                    virtual observatory, and data distribution systems comprise Space Environment Canada.
                </p>
                <p style={{ textAlign: "justify" }}>
                    Space Environment Canada is led by three Alberta Universities. The five PIs of the infrastructure
                    that make up the facility are all former trainees of the three CANOPUS PIs (Gordon Rostoker,
                    1990-2000; John Samson, 2000-2002; Eric Donovan, 2002-2003). Eric Donovan and Ian Mann were
                    the lead authors of the proposal to CSA for Canadian Geospace Monitoring, the program that
                    continued operations funding for the evolving network. For programmatic reasons, the foremost
                    being limits to the size of CSA grants for operations, these networks have operated separately,
                    but always with an eye to common science and academic objectives. That said, the networks overlap,
                    and the instruments have been chosen to provide the world unique view afforded by the sum of the
                    parts.
                </p>
                <p style={{ textAlign: "justify" }}>
                    Space Environment Canada exists in a rich eco-system of international space missions, the NASA
                    component of which is called
                    the <a href="https://www.nasa.gov/content/goddard/heliophysics-system-observatory-hso"
                        rel="noreferrer">Heliophysics System Observatory</a>. More than 30 scientific satellites,
                    including THEMIS, Swarm, Elfin, Cluster, MMS, Arase, GOES, DMSP, POES, and others, provide
                    direct observations of the fundamental processes that underpin Space Weather, while Space
                    Environment Canada provides a multi-parameter multi-scale view of how those processes evolve
                    to have those system-level consequences. This facility is critical for Canada's role in the
                    international geospace research effort.
                </p>

                <br />

                <h3>Notable Papers</h3>
                <ul>
                    <li>Angelopoulos, V., The THEMIS Mission, Space Science Reviews,
                        2008.{' '}<a href="https://doi.org/10.1007/s11214-008-9336-1" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Frey, H.U., S.B. Mende, V. Angelopoulos, E.F. Donovan, Substorm onset observations
                        by IMAGE-FUV, J. Geophys. Res. 109, A10304,
                        2004{' '}<a href="https://doi.org/10.1029/2004JA010607" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Harris, S.E., S.B. Mende, V. Angelopoulos, W. Rachelson, E. Donovan, B. Jackel, M. Greffen,
                        C.T. Russell, D.R. Pierce, D.J. Dearborn, K. Rowe, and M. Connors, THEMIS, Ground based
                        observatory system design, Space Sci. Rev. Online First (SSRv Homepage),
                        2007{' '}<a href="https://doi.org/10.1007/s11214-007-9294-z" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Mann, I. R., D. K. Milling, I. J. Rae, L. G. Ozeke, A. Kale, Z. C. Kale, K. R. Murphy, A. Parent,
                        M. Usanova, D. M. Pahud, E.-A. Lee, V. Amalraj, D. D. Wallis, V. Angelopoulos, K.-H. Glassmeier,
                        C. T. Russell, H.-U. Auster, and H. J. Singer, The Upgraded CARISMA Magnetometer Array in the
                        THEMIS Era, Space Science Reviews,
                        2008.{' '}<a href="https://doi.org/10.100li/s11214-008-9457-6" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Mende, S. E., Harris, H. U. Frey, V. Angelopoulos, C. T. Russell, E. Donovan, B. Jackel,
                        M. Greffen, and L. M. Peticolas, The THEMIS Array of Ground-based Observatories for the
                        Study of Auroral Substorms, Space Science Reviews,
                        2008.{' '}<a href="https://doi.org/10.1007/s11214-008-9380-x" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Loomis, E., On the geographic distribution of auroras in the northern hemisphere, American
                        Journal of Science and Arts, 30, 89–94, 1860. </li>
                    <li>Chapman and Ferraro, A new theory of magnetic storms, Journal of Jeophysical Research,
                        1931.{' '}<a href="https://doi.org/10.1029/TE036i003p00171" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Feldstein, Y. I., Some problems concerning the morphology of auroras and magnetic disturbances
                        at high latitudes, Geomagnetism and Aeronomy, 3, 183–192, 1963.</li>
                    <li>Rostoker, G., Macrostructure of geomagnetic bays, Journal of Geophysical Research,
                        1968.{' '}<a href="https://doi.org/10.1029/JA073i013p04217" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Samson, J., J. Jacobs, and G. Rostoker, Latitude-dependent characteristics of long-period
                        geomagnetic micropulsations, Journal of Gephysical Reseach,
                        1971.{' '}<a href="https://doi.org/10.1029/JA076i016p03675" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Samson, J., L. Cogger, and Q. Pao, Observations of field line resonances, auroral arcs, and
                        auroral vortex structures, Journal of Geophysical Research,
                        1996.{' '}<a href="https://doi.org/10.1029/96JA01086" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                    <li>Voronkov, I, E. Donovan, and J. Samson, Observations of the phases of the substom, Journal
                        of Geophysical Research,
                        2003.{' '}<a href="https://doi.org/10.1029/2002JA009314" target="_blank"
                            rel="noreferrer">{"<paper>"}</a></li>
                </ul>
                <br />
                <br />


            </Container>
        </div>
    );
};

export default HistoryPage;