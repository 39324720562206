import React from "react";
import { Route, Switch } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import DataPage from "./pages/DataPage";
import HistoryPage from "./pages/HistoryPage";
import AboutPage from "./pages/AboutPage";
import GovernancePage from "./pages/GovernancePage";
import DataPolicyPage from "./pages/DataPolicyPage";
import { useGoogleAnalytics } from './utils/analytics';
import SECFooter from "./components/Footer";
import SECNav from "./components/Nav";

const withHeader = (pageContent, homepage = false) => {
    return (
        <>
            <SECNav homepage={homepage} />
            <div className="d-flex flex-column" style={{ minHeight: "100%", justifyContent: "space-between" }}>
                {pageContent}
                <SECFooter />
            </div>
        </>
    );
};

const Routes = () => {
    useGoogleAnalytics();

    return (
        <Switch>
            <Route exact path="/about" render={() => {
                return (
                    withHeader(<AboutPage />)
                );
            }}>
            </Route>
            <Route exact path="/governance" render={() => {
                return (
                    withHeader(<GovernancePage />)
                );
            }}>
            </Route>
            <Route exact path="/data_policy" render={() => {
                return (
                    withHeader(<DataPolicyPage />)
                );
            }}>
            </Route>
            <Route exact path="/history" render={() => {
                return (
                    withHeader(<HistoryPage />)
                );
            }}>
            </Route>
            <Route exact path="/data" render={() => {
                return (
                    withHeader(<DataPage />)
                );
            }}>
            </Route>
            <Route path="/" render={() => {
                return (
                    withHeader(<HomePage />, true)
                );
            }}>
            </Route>
        </Switch>
    );
};

export default Routes;