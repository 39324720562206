import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import '../styles/data.scss';

const DataPage = () => {
    return (
        <div>
            <Container fluid="sm" className="header text-center" style={{paddingTop: 100}}>
                <h1>Data</h1>
            </Container>
            <Container fluid="sm" className="pt-4">
                <Row className="d-flex flex-row pt-3 text-center justify-content-center spacing-1">
                    <Col xs={12} md={6} style={{ textAlign: "justify" }}>
                        <h3 style={{ textAlign: "left" }}>Magnetic Field</h3>
                        <p>
                            Flux gate magnetometers and induction coil magnetometers measure variations and waves
                            in the magnetic field at the ground. From these observations, researchers infer ionospheric
                            currents driven by the solar wind magnetosphere interaction, and by internal instabilities
                            and waves within the magnetoshere and on its surface (the magnetopause). These data are used
                            in studies of magnetic storms and substorms, radiation belt physics, magnetosphere-ionosphere
                            coupling including the aurora, global Ultra-Low Frequency (LF) waves modes in magnetosphere,
                            the Schuman resonances, ionospheric Alfvén resonator, EMIC waves in the magnetosphere, and
                            ground-induced currents that are important in understanding and quantifying effects of space
                            weather on pipelines and power grids. Three networks of magnetometers span Canada at subauroral
                            and auroral latitudes, and extend into the polar cap.
                        </p>
                        <p>Magnetometer data from this facility is freely available from three sources:</p>
                        <ol>
                            <li><a href="https://www.carisma.ca/carisma-data-repository" target="_blank"
                                rel="noreferrer">CARISMA flux fate and induction coil data</a></li>
                            <li><a href="http://autumn.athabascau.ca/" target="_blank"
                                rel="noreferrer">AUTUMN flux gate data</a></li>
                            <li><a href="http://themis.ssl.berkeley.edu/data/themis/thg/ascii_data/mag/"
                                target="_blank" rel="noreferrer">THEMIS flux gate</a></li>
                        </ol>
                        <p>
                            IDL and Python libraries exist for calling CARISMA and THEMIS data, and we are working on
                            equivalent resources for AUTUMN. Our goal is that with three lines of code, one will be able
                            to retrieve magnetometer data from the three arrays.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: "justify" }}>
                        <h3 style={{ textAlign: "left" }}>Optical</h3>
                        <p>
                            The present network of ASIs includes the TREx-ASI, REGO, and THEMIS arrays. The white
                            light (panchromatic) THEMIS network has a contiguous field of view (fov) spanning the
                            continent at typical auroral latitudes. REGO delivers images from a contiguous field of
                            view extending from Hudson Bay to Alaska, and from well equatorward of to well poleward
                            of the typical auroral zone. TREx-ASI images the aurora across a region in central
                            Canada spanning three MLT hours and in latitude the typical magnetic footprint of the
                            nightside transition region (59 to 71 degrees AACGM). TREx images in five channels:
                            near infrared, N2+ bueline, and three channels from full-color RGB.
                        </p>
                        <p>
                            As well, the facility includes two meridian imaging spectrographs and a proton
                            auroral scanning photometer. The former are on one meridian (Lucky Lake/Rabbit
                            Lake), and the photometer operates at AUGO.
                        </p>
                        <p>
                            Data from all three networks of ASIS, and the MISs and proton auroral photometer
                            are SPEDAS readable, and available from:
                        </p>
                        <ol>
                            <li><a href="https://data.phys.ucalgary.ca/" target="_blank"
                                rel="noreferrer">The University of Calgary Auroral Imaging Group (AIG) Data Landing Page</a></li>
                            <li><a href="http://themis.ssl.berkeley.edu/data_all.shtml"
                                target="_blank" rel="noreferrer">The THEMIS data site maintained by UC Berkeley</a></li>
                        </ol>
                        <p>
                            Note that we are endeavoring to get all of our historical data online at the AIG
                            page, and at present this includes data from CANOPUS ASI and MSP, NORSTAR ASI and
                            MSP, Rainbow, DASI, and NASCAM, providing a continuous data set from the late 1980s,
                            all openly available on
                            the <a href="https://data.phys.ucalgary.ca/" target="_blank"
                                rel="noreferrer">University of Calgary Auroral Imaging Group (AIG) Data
                                Landing Page</a>. While much of the earlier data is not SPEDAS readable, it
                            will be, and it can be read via tools that can be obtained
                            by <a href="mailto:edonovan@ucalgary.ca">contacting the University of Calgary Auroral Imaging Group</a>.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: "justify" }}>
                        <h3 style={{ textAlign: "left" }}>Incoherent Scatter Radar</h3>
                        <p>
                            The Canadian face of the Resolute Bay Incoherent Scatter radar (RISR-C) is a
                            powerful ionospheric diagnostic that operates on a campaign basis. RISR-C is the
                            compliment to RISR-N, operated for NSF by SRI International, also at Resolute Bay.
                            All RISR-C data is available
                            on <a href="mailto:rgllies@ucalgary.ca">request from the University of Calgary</a>, the
                            University of Calgary Space Physics
                            Group <a href="https://data.phys.ucalgary.ca/" target="_blank" rel="noreferrer">Data
                                Landing Page</a>, and the NSF
                            funded <a href="mailto:dchaddoc@ucalgary.ca">MADRIGAL</a> virtual observatory maintained
                            by MIT's Haystack Observatory in Massachusetts.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: "justify" }}>
                        <h3 style={{ textAlign: "left" }}>Riometers</h3>
                        <p>
                            The existing single beam riometers were installed in the late 1980s as part of
                            the MARIA component of CANOPUS. They are presently being replaced with new
                            multi-frequency single beam riometers under the SWAN CFI JELF award, and
                            augmented by a new network of imaging riometers under the TREx CFI Innovation
                            Fund award. Data from the existing array, dating back to late 1980s, is
                            available on
                            the <a href="https://data.phys.ucalgary.ca/" target="_blank"
                                rel="noreferrer">University of Calgary Space Physics Group Data Landing Page</a>.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: "justify" }}>
                        <h3 style={{ textAlign: "left" }}>Athabasca University Geophysical Observatory</h3>
                        <p>
                            AUGO is a base for instrument development, and for operation of permanently deployed
                            instruments (including THEMIS-ASI, THEMIS-GMAG, TREx-ASI, and the AUGO proton auroral
                            photometer). As well, on-site users of AUGO from around the world operate (mostly)
                            optical instruments with specific science
                            objectives (<a href="http://augo.athabascau.ca/index.php" target="_blank"
                                rel="noreferrer">see more</a>). Data from specific experiments are available
                            via <a href="mailto:martinc@athabascau.ca">contact with the AUGO</a>.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: "justify" }}>
                        <h3 style={{ textAlign: "left" }}>AuroraX</h3>
                        <p>
                            This is a Canada Foundation for Innovation cyber-infrastructure project which
                            has the objective of making the vast auroral data set (just THEMIS-ASI has more
                            than 1.2 billion image) 'discoverable.' AuroraX, funded by the Canada Foundation
                            for Innovation, the Alberta government, the European Space Agency, and the Danish
                            Technological University, has at its core a database and conjunction finder. The
                            platform enables researchers to search for instances where one or more satellites
                            are in conjunction with imagers and where those imagers have aurora of specific
                            types in their fields of view.
                            The <a href="https://aurorax.space/conjunctionSearch/dropdowns" target="_blank"
                                rel="noreferrer">conjunction finder</a> identifies optical-in situ
                            conjunctions, but will be extended to include riometer and magnetometer data,
                            as well.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DataPage;