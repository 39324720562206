import React, { useEffect, useState, useRef } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import clsx from 'clsx';
import { BiLinkExternal } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/navbar.scss';

const SECNav = ({ homepage }) => {
    const [navBackground, setNavBackground] = useState(false);
    const navRef = useRef();
    const location = useLocation();
    navRef.current = navBackground;

    useEffect(() => {
        const handleScroll = () => {
            if (homepage) {
                const show = window.scrollY > 20;
                if (navRef.current !== show) {
                    setNavBackground(show);
                }
            }
        };
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [homepage]);

    return (
        <Navbar
            variant="dark"
            expand="lg"
            fixed="top"
            collapseOnSelect
            className={clsx("padded", !homepage || navBackground ? "navbar-scroll" : "navbar-no-scroll")}>
            <Container fluid="sm">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav style={{ marginLeft: "auto" }} activeKey={location.pathname}>
                        <Nav.Link className={`nav-link`} href="/">Home</Nav.Link>
                        <Nav.Link className={`nav-link`} href="/about">About</Nav.Link>
                        <Nav.Link className={`nav-link`} href="/governance">Governance</Nav.Link>
                        <Nav.Link className={`nav-link`} href="/data_policy">Data Policy</Nav.Link>
                        <Nav.Link className={`nav-link`} href="/history">History</Nav.Link>
                        <a className={`nav-link`} href="https://data.space-environment.ca" target="_blank" rel="noreferrer">Data <BiLinkExternal /></a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default SECNav;