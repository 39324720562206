import React from "react";
import { Container } from "react-bootstrap";
import '../styles/governance.scss';

const GovernancePage = () => {
    return (
        <div>
            <Container fluid="sm" className="header text-center" style={{paddingTop: 100}}>
                <h1>Governance</h1>
            </Container>
            <Container fluid="sm" className="pt-4 pb-5">
                <div className="row">
                    <div className="col-lg-6">
                        <a href="/img/governance_diagram1.png" target="_blank">
                            <img src="/img/governance_diagram1.png" className="img-fluid" alt="SEC Governance diagram" />
                        </a>
                    </div>
                    <div className="col-lg-6 governance-text1">
                        <p style={{ textAlign: "justify" }}>
                            AthabascaU, UAlberta, and UCalgary have implemented, maintained, and grown an
                            internationally renowned national network of instrumentation, now operated under
                            the umbrella of Space Environment Canada (SEC). SEC has a set of operating
                            principles which were established in the 2003 formation of Canadian GeoSpace
                            Monitoring which presaged the quadrupling of ground-based instruments that are
                            now encapsulated by SEC. This emphasized extended arrays probing the same region
                            with networks within networks to probe a range of scales.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <i>SEC is continuously evolving</i> as we work towards a set of strategic objectives
                            such as "extend magnetometer coverage deeper into the Arctic". We hold and participate
                            in numerous workshops and international/national meeting each year where SEC strategy
                            and performance are key topics. Delivering on our mission demands evolution, informed
                            by extensive community consultation and extensive discussions among us.
                        </p>
                    </div>
                </div>

                <hr className="mt-5 mb-5" />

                <div className="row">
                    <div className="col-lg-6">
                        <a href="/img/governance_diagram2.png" target="_blank">
                            <img src="/img/governance_diagram2.png" className="img-fluid" alt="SEC Governance diagram" />
                        </a>
                    </div>
                    <div className="col-lg-6 governance-text2">
                        <p style={{ textAlign: "justify" }}>
                            Our management structures have evolved over the course of decades to include
                            cooperation, coordination of activities and shared O&M best practices. SEC
                            management structure provides a formal framework for facility O&M and resource
                            sharing, while clearly defining roles and responsibilities of each operations
                            team. We have found this an effective structure that allows for autonomy of
                            institutional teams while creating buy-in to an efficient common operating
                            framework.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default GovernancePage;