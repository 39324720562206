import React from "react";
import { Container } from "react-bootstrap";
import LandingHero from "../components/LandingHero";
import '../styles/landing.scss';
import DiscoverySection from "../components/DiscoverySection";
import CommunitySection from "../components/CommunitySection";

const HomePage = () => {
    const buttons = [
        {
            to: "/about",
            title: "discovery",
            description: "Advancing knowledge of the fundamental physical processes at work in geospace, and the multi-scale dynamics through which they have system-level effects.",
        },
        {
            to: "/data",
            title: "scholarship",
            description: "Providing outstanding opportunities for training of highly qualified personnel, and engaging material for education at all levels.",
        },
        {
            to: "/network",
            title: "solutions",
            description: "Innovating to develop new instruments and observational methods, and to develop actionable space weather information.",
        },
        {
            to: "/team",
            title: "community",
            description: "Engaging the public through public outreach and citizen science.",
        },
    ];

    return (
        <div>
            <LandingHero buttons={buttons} bgImage="/img/landing.jpg" title="Space Environment Canada" subtitle={null} />
            <Container id="intro-text" className="pt-3">
                <DiscoverySection />
                <CommunitySection />
            </Container>
        </div>
    );
};

export default HomePage;