import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImageCard from "./ImageCard";

const COMMUNITY_CARDS = [
    {
        title: "Aurora Watch",
        url: "https://www.aurorawatch.ca/",
        imageUrl: "/img/community/aurorawatch.png",
        text: "AuroraWatch is a website that provides a real-time monitor of geomagnetic activity in the Edmonton area.  Explore current geomagnetic conditions, learn about the solar wind and its connection to the aurora, and sign up to have an alert emailed to you directly if there might be aurora visible tonight.",
    },
    {
        title: "AuroraMAX",
        url: "https://www.asc-csa.gc.ca/eng/astronomy/northern-lights/auroramax-observatory.asp",
        imageUrl: "/img/community/auroramax.png",
        text: "Auroral Enthusiasts can watch the Aurora Borealis in real time through the University of Calgary’s AuroraMAX high-definition live camera feed during the winter season between August and May.",
    },
    {
        title: "Auroral Zone",
        url: "https://auroralzone.org/",
        imageUrl: "/img/community/auroralzone.png",
        text: "Contribute to the global auroral community and increase your knowledge about the Aurora Borealis.   This is about big data, imaging the night sky, and appreciating the spectacular natural phenomenon that defines the Canadian North.",
    },
    {
        title: "Athabasca University Geophysical Observatory II (AUGO II)",
        url: "http://augo.athabascau.ca/information/augo2/",
        imageUrl: "/img/community/augoii.png",
        text: "Featuring six climate-controlled domed optical observation suites, on-site accommodation, and dark skies free of light pollution from urban development, AUGO II is Canada’s newest and most complete subauroral observatory.",
    },
    {
        title: "Instructional Media",
        url: "https://www.ted.com/talks/dr_eric_donovan_how_i_met_steve_the_discovery_of_a_new_aurora",
        imageUrl: "/img/community/instructional_media.png",
        text: "Access short media clip conversations about the aurora.  View comprehensive talks outlining various forms of aurora and learn how space weather impacts the near-Earth environment.  Hear discussions on new discoveries such as STEVE and listen to new advances in space and citizen science.",
    },
    {
        title: "Keogramist",
        url: "https://aurorax.space/keogramist/",
        imageUrl: "/img/community/keogramist.png",
        text: "The AuroraX Keogramist web tool allows you to explore world of aurora.  Search through keogram data and then immerse yourself in the first-person view of your event using the AuroraDome interface.  Create movies of the data to share and enjoy the beauty of the northern lights.",
    },
];

const CommunitySection = () => {
    return (
        <Container id="community" fluid className="py-3">
            <div id="community-scroll" style={{ position: "relative", top: -70 }} />
            <h3>Community</h3>
            <Row className="pt-3">
                {
                    COMMUNITY_CARDS.map((cardInfo, index) => {
                        return (
                            <Col xs={1} md={3} lg={4} className="mt-2 mb-4 px-0" key={`community-section-${index}`}>
                                <ImageCard imageUrl={cardInfo.imageUrl} heading={cardInfo.title} text={cardInfo.text} headingUrl={cardInfo.url} />
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    );
};

export default CommunitySection;