import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ImageCard = ({
    imageUrl,
    headingUrl,
    heading,
    text
}) => {
    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <div className="text-section-img"
                        style={{
                            height: "100%",
                            minHeight: 250,
                            width: "100%",
                            backgroundImage: `url(${imageUrl})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            cursor: headingUrl ? "pointer" : "default",
                        }}
                        onClick={headingUrl ? () => window.open(headingUrl, "_blank") : null} />
                </Col>
            </Row>
            <Row className="d-flex mt-3">
                <Col xs={12}>
                    <h4>
                        {
                            headingUrl ?
                                <a href={headingUrl} target="_blank" rel="noreferrer">{heading}</a>
                                : heading
                        }
                    </h4>
                    <p>
                        {text}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default ImageCard;