import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";


const LandingHero = ({
    bgImage,
    title,
    // eslint-disable-next-line
    subtitle,
    buttons,
}) => {
    const nextSection = (sectionName) => {
        let element = document.getElementById(`${sectionName}-scroll`);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    return (
        <Container fluid className="d-flex align-items-center justify-content-center landing"
            style={{
                minHeight: "100vh",
                backgroundImage: `url(${bgImage}), linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.5))`,
                backgroundBlendMode: "overlay",
                backgroundPosition: "center",
                backgroundSize: "cover",
            }}>
            <Container fluid="xs">
                <Row>
                    <Col xs={12}>
                        <h1>{title}</h1>
                    </Col>
                </Row>
                <Row className="d-flex pt-4 ">
                    <Col xs={12}>
                        <div style={{ width: "80%", margin: "auto" }}>
                            <h2>Our mission: provide a globally unique view of the multi-scale,
                                multi-parameter physical processes at work in the near-Earth space
                                environment through which kinetic-scale fundamental processes have
                                global-scale space weather consequences.
                                <br />
                                <br />
                                Our vision: maintain and enhance our global role in geospace research
                                through fundamental research, international partnership, space weather
                                solutions, instrument development, machine learning, and data
                                visualization.
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-5 d-flex flex-row justify-content-center" style={{ width: "80%", margin: "auto" }}>
                    {buttons.map((btn, index) => {
                        return (
                            <Col className={index !== buttons.length - 1 ? "border-right" : ""}
                                xs={12}
                                md={3}
                                style={["discovery", "community"].includes(btn.title) ? { padding: "1rem", cursor: "pointer" } : { padding: "1rem" }}
                                key={index}
                                onClick={() => { if (["discovery", "community"].includes(btn.title)) { return nextSection(btn.title); } }}>
                                <h3>{btn.title}</h3>
                                <p>{btn.description}</p>
                            </Col>);
                    })}
                </Row>
            </Container>
            <BsChevronDown id="scroll-to-intro" fill="white" onClick={() => nextSection("discovery")} />
        </ Container>
    );
};

export default LandingHero;